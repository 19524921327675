import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import AppStoreButton from "../components/app-store-button"

// styles
import "../styles/main.scss"

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hit Your Steps</title>
        <link rel="canonical" href="https://www.hityoursteps.com" />
        <meta name="description" content="iPhone app to help you learn how many steps per mile/km you walk. So you can reach you step goals each day."/>
        <meta name="apple-itunes-app" content="app-id=1590501792"/>
      </Helmet>
      <section>
        <section class="left">
          <p class="illustration"><StaticImage src="../images/walking-image.png" alt="Walking shoes illustration" placeholder="blurred" width={400}/></p>
          <p>Use Hit Your Steps on your iPhone to see your average steps per mile/km over the last 90 days.</p>
          <p>See how many miles/kms you need to walk each day to reach your step goals.</p>
          <p>Find you Walking Routes recorded on your Apple Watch, that with help you Hit Your Steps.</p>
          <p><a href="https://stephenkeable.medium.com/hit-your-steps-cee6458e2b9d">Read the origin story of Hit Your Steps</a></p>
          <div/>
          <p class="badge">
            <AppStoreButton/>
          </p>
        </section>
        <section class="right">
          <StaticImage src="../images/iphone-12-mini-screenshot.png" alt="iPhone 12 mini screenshot" placeholder="blurred"/>
        </section>
      </section>
    </Layout>
  )
}

export default IndexPage
